import { useState, useEffect } from "react";
import toast from "react-hot-toast";

import { useMediaQuery } from "@mui/material";
import { GilroyFontDark, GilroyFontLight } from "@lib/GilroyFont";
import "react-phone-number-input/style.css";
import { useRouter } from "next/router";
import TimezoneSelector from "./TimezoneSelector";
import {
  options,
  BudgetValues,
  initialValues,
  BeginProject,
  hiringDecision,
} from "../data/formData";
import { formValidationSchema } from "../utils/formValidations";

import useDynamicFormik from "./../hooks/useDynamicFormik";
import timeStampGenerator from "./../lib/timeStampGenerator";
import {
  sendEmail,
  sendEmailToCustomer,
  sendDataToSheets4,
} from "./../lib/sendEmail";
import { v4 as uuidv4 } from 'uuid';
import InputComponent from "./InputComponent";
import SelectComponent from "./SelectComponent";
import PhoneInputCustom from "./PhoneInputCustom";
//import ReCAPTCHA from "react-google-recaptcha";
import useClientIP from "@hooks/useClientIP";
import AfterFormSubmit from "@components/AfterFormSubmit";
import axios from "axios";

const Form = ({ isVisible, close, heading, subheading, width }) => {
  const isMobile = useMediaQuery("(max-width:786px)");
  //const isPixelActive = useMouseOver();
  const router = useRouter();

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionBudget, setSelectedOptionBudget] = useState(null);
  const [selectProjectBegin, setSelectProjectBegin] = useState(null);
  const [selectHiringDecision, setSelectHiringDecision] = useState(null);
  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const [selectedTime, setSelectedTime] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [showCaptchaError, setShowCaptchaError] = useState(false);

  const ipAddress = useClientIP();
  const [isChecked, setIsChecked] = useState(true);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);

  };
  useEffect(() => {
    if (showCaptchaError === true) {
      setShowCaptchaError(false);
    }
  }, [recaptchaToken]);

  const handleTimeChange = (timeZone, timeSlot) => {
    const timeToCall = `${timeZone} ${timeSlot?.label ? timeSlot?.label : ""} - ${timeSlot?.end ? timeSlot?.end : ""}`;
    setSelectedTime(timeToCall);

    console.log("here", timeToCall);
  };
  async function sendconvertionEvent(eventName, eventData) {
    try {
      const response = await axios.post('/api/send-event', {
        eventName,
        userData: eventData.userData,
        customData: eventData.customData,
        clickId: eventData.clickId,
        userAgent: eventData.userAgent,
        browserId: eventData.browserId,
        externalId: eventData.externalId,
        ipAddress: eventData.ipaddress,
      });
  
      console.log('Event sent successfully:', response.data);
    } catch (error) {
      console.error('Error sending event:', error.response ? error.response.data : error.message);
    }
  }
  function getBrowserId() {
    // Logic to get Facebook Browser ID (fbp)
    const fbpCookie = document.cookie
      .split('; ')
      .find(row => row.startsWith('_fbp='));
    return fbpCookie ? fbpCookie.split('=')[1] : undefined;
  }
  function getClickId() {
    // Check for _fbc cookie
    const fbcCookie = document.cookie
      .split('; ')
      .find(row => row.startsWith('_fbc='));
  
    if (fbcCookie) {
      console.log('Returning _fbc from cookie:', fbcCookie.split('=')[1]);
      return fbcCookie.split('=')[1];
    } else {
      console.warn('No _fbc cookie found');
    }
  
    // Generate _fbc from fbclid query parameter if _fbc cookie does not exist
    const urlParams = new URLSearchParams(window.location.search);
    const fbclid = urlParams.get('fbclid');
    if (fbclid) {
      const subdomainIndex = 1; // Adjust this based on your domain structure
      const creationTime = Math.floor(new Date().getTime() / 1000); // Use current time as creation time
      const generatedFbc = `fb.${subdomainIndex}.${creationTime}.${fbclid}`;
      console.log('Generated _fbc from fbclid:', generatedFbc);
      return generatedFbc;
    } else {
      console.warn('No fbclid query parameter found');
    }
  
    // Generate a fallback _fbc value
    const subdomainIndex = 1;
    const creationTime = Math.floor(new Date().getTime() / 1000);
    const placeholderFbclid = '0000000000000000'; // Placeholder if no fbclid is found
    const fallbackFbc = `fb.${subdomainIndex}.${creationTime}.${placeholderFbclid}`;
    console.log('Generated fallback _fbc:', fallbackFbc);
    return fallbackFbc;
  }
  const onSubmit = async (values) => {
    const form_service_type =
      selectedOption?.map((option) => option.label).join(", ") ||
      "";
    const form_service_type_budget = selectedOptionBudget?.value
      ? selectedOptionBudget.value
      : "";

    const form_service_type_projectBegin = selectProjectBegin?.value
      ? selectProjectBegin.value
      : "";

    const form_service_type_hiringDecision = selectHiringDecision?.value
      ? selectHiringDecision.value
      : "";

    try {
  /*    const response = await fetch("/api/verify-captcha", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ responseToken: recaptchaToken }),
      });

      const data = await response.json();
      if (response.ok) { */
        const utcMinus11Timestamp = timeStampGenerator();
        let split_data = utcMinus11Timestamp.split(', ')
  let date_str = split_data[0]
  let time_str = split_data[1]

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return `(${match[2]}) ${match[3]}-${match[4]}`;
    }
    return phoneNumber;
};

const formattedPhoneNumber = formatPhoneNumber(formik.values.phone);
const userAgent = navigator.userAgent;
      const clickId = getClickId(); // Replace with your actual click ID logic
      const browserId = getBrowserId(); // Function to get Facebook Browser ID (fbp)
      const externalId = uuidv4(); // Replace with your actual external ID logic
      const ipaddress = ipAddress; 


  if (isChecked) {

    await sendconvertionEvent('Lead', {
      userData: {
        fn: values.name,
        email: values.email,
        ph: formattedPhoneNumber,
      },
      customData: {
        content_type: 'Content Form Submit',
        page_path: router.pathname,
      },
      clickId,
      userAgent,
      browserId,
      externalId,
      ipaddress,
    });

  //      sendLeadEvent(
    //      "Lead",
      //    formik,
        //  "Content Form Submit",
       //   router.pathname,
       //   isPixelActive,
       // );


        const newRow = {
          Date: date_str,
           // Time: time_str,
           State: "",
           Source: "Common Form",
           Assigned: "",
           Status: "Fresh",
           Name: formik.values.name,
           Email: formik.values.email,
           Number: formattedPhoneNumber,
           Company: formik.values.business,
           "Client\nNotes": `${form_service_type_budget} ${selectedTime ? ('/') : ('')} ${selectedTime} ${form_service_type ? ('/') : ('')}  ${form_service_type} ${form_service_type_projectBegin ? ('/') : ('')} ${form_service_type_projectBegin} ${form_service_type_hiringDecision ? ('/') : ('')} ${form_service_type_hiringDecision} ${formik.values.message ? ('/') : ('')} ${formik.values.message} ${ipAddress ? ('/') : ('')} ${ipAddress}`,
           // Services: form_service_type,
           // Message: formik.values.message,
           // IP: ipAddress,
        };

        const gSheets = await sendDataToSheets4(newRow);

        formik.setStatus({
          success: true,
          message: "Form submitted successfully",
        });

        if (!isVisible) {
          close(false);
        }
        setIsFormSubmited(true);
        if (!isVisible) {
          close(false);
        }
        
        const emailResponse = await sendEmail(
          formik,
          form_service_type_budget,
          form_service_type,
          form_service_type_projectBegin,
          form_service_type_hiringDecision,
          newRow,
          ipAddress,
        );

        const sendEmailToUser = await sendEmailToCustomer(
          formik,
          form_service_type_budget,
          form_service_type,
          form_service_type_projectBegin,
          form_service_type_hiringDecision,
          newRow,
        );

        if (emailResponse.data === "Success") {
          // toast.success("Thank you!, Our representative will contact You.");
          formik.setStatus({
            success: true,
            message: "Form submitted successfully",
          });

          if (!isVisible) {
            close(false);
          }
          setIsFormSubmited(true);
          if (!isVisible) {
            close(false);
          }
        } else {
          formik.setStatus({ success: false, message: "Submission error" });

          toast.error("Please Retry again.");
        }
      } else {console.log("select a checkbox")}

        setShowCaptchaError(false);
   /*   } else {
        console.error(data.message);
        toast.error("Please fill recaptcha");
        setShowCaptchaError(true);
      }*/
    } catch (error) {
      /* eslint-disable */
      console.log(error.message);
      formik.setStatus({ success: false, message: error.message });
    }
  };

  const formik = useDynamicFormik(
    initialValues,
    formValidationSchema,
    onSubmit,
  );

  const onRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  // if(formik?.status?.success === true) {
  //   close
  //   return (
  //     <div className='bg-white p-10 rounded-xl text-2xl'> Thanks For Filling the form </div>
  //   )

  // }
  const getSiteKey = () => {
    if (typeof window !== 'undefined') {
      const currentDomain = window.location.hostname;
      console.log('dd',currentDomain)
  
      switch (currentDomain) {
        case 'techcelerant.com':
          return process.env.NEXT_PUBLIC_RECAPTCHA_PUBLIC_KEY;
        case 'www.techcelerantagency.com':
          return process.env.NEXT_PUBLIC_RECAPTCHA_PUBLIC_KEY_AGENCY;
        case 'www.techcelerantinc.com':
          return process.env.NEXT_PUBLIC_RECAPTCHA_PUBLIC_KEY_INC;
        default:
          return process.env.NEXT_PUBLIC_RECAPTCHA_PUBLIC_KEY;
      }
    }
  
    return '';
  };
  return (
    <div>
      <div
        className={` flex flex-col  py-10 ${width ? width : "max-w-[80rem]"}  mx-auto pb-[5rem] pl-mobile `}
      >
        {/* <CommonForm className="rounded-full sm:p-6 w-full flex flex-col lg:flex-row  sm:space-x-2 mt-10"  className2='flex sm:justify-center items-center'
      className3="flex flex-col  items-start justify-center min-w-[40vw] max-w-[100vw] sm:max-w-[50vw] bg-white z-10 p-10  rounded-2xl"
       isVisible={isVisible} close={close}>

<div>
          <h3 className={` ${GilroyFontDark.className} text-white  heading-text`}>
            {heading ? heading : 'Get Free Consultancy'}
          </h3>
          <p className={` ${GilroyFontLight.className} p-home pb-5 `}>
            {subheading ? subheading : 'When it comes to a dependable, professional, and cost-effective digital service provider, Techcelerant stands as the unrivaled choice. Our track record speaks for itself, and we are poised to demonstrate our expertise. Reach out to us today to schedule a complimentary consultation and allow us the opportunity to showcase the value we can bring to your business.'}
          </p>
          </div>

</CommonForm> */}

        <form
          onSubmit={formik.handleSubmit}
          className=" rounded-full sm:p-6 w-full flex flex-col lg:flex-row  sm:space-x-2 mt-10"
        >
          <div className="sm:max-w-[50vw]">
            <h3
              className={` ${GilroyFontDark.className} text-white  heading-text`}
            >
              {heading ? heading : "Get Free Quote"}
            </h3>
            <p className={` ${GilroyFontLight.className} p-home pb-5 `}>
              {subheading
                ? subheading
                : "When it comes to a dependable, professional, and cost-effective digital service provider, Techcelerant stands as the unrivaled choice. Our track record speaks for itself, and we are poised to demonstrate our expertise. Reach out to us today to schedule a complimentary consultation and allow us the opportunity to showcase the value we can bring to your business."}
            </p>
          </div>

          {formik?.status?.success === true ? (
            <AfterFormSubmit />
          ) : (
            <>
              <div className=" flex sm:justify-center items-center">
                <div className="flex flex-col  items-start justify-center min-w-[20vw] max-w-[100vw] sm:max-w-[50vw] bg-white z-10 px-10 py-4  rounded-2xl ">
                  <label className="w-[100%]">
                  <InputComponent
                    required={true}
                    className="w-[100%] border-b-[5px] border-gray-300 text-gray-800 outline-none"
                    value="name"
                    id="name-input"
                    placeholder="Full Name"
                    formik={formik}
                  />
                  </label>
                  <label className="w-[100%]">
                  <InputComponent
                    required={true}
                    className="w-[100%] border-b-[5px] border-gray-300 text-gray-800 outline-none"
                    value="email"
                    id="email-input"
                    placeholder="Email"
                    formik={formik}
                  />
              </label>
              <label className="w-[100%]">

                  <PhoneInputCustom formik={formik} />
                  </label>
                  {isVisible && (
                    <>
                    <label>
                      <SelectComponent
                      id="options-input"
                        className="mb-2 -ml-[3px] border-b-[5px] border-gray-300"
                       // required={true}
                        selectWidth="31rem"
                        isMobile={isMobile}
                        defaultValue={formik.values["selectedOption"]}
                        //value="selectedOption"
                        onChange={(selectedOption) => {
                          formik.setFieldValue(
                            "selectedOption",
                            selectedOption
                              ? selectedOption.map((option) => option.value)
                              : [],
                          );
                          setSelectedOption(selectedOption);
                        }}
                        formikTouched={formik.touched["selectedOption"]}
                        formikError={formik.errors["selectedOption"]}
                        options={options}
                        placeholder="Choose Services"
                        aria-label="Choose Services"
                        instanceId="react-select-services1"
                        label="Choose Services"
                        multi={true}
                        mobileWidth="17rem"
                        formik={formik}
                        value={selectedOption}
                      />
</label>
<label>
                      <SelectComponent
                      id="budget-input"
                        className="mb-2 -ml-[3px] border-b-[5px]  border-gray-300"
                       // required={true}
                        selectWidth="31rem"
                        isMobile={isMobile}
                        defaultValue={formik.values["selectedOptionBudget"]}
                        onChange={(selectedOptionBudget) => {
                          formik.setFieldValue(
                            "selectedOptionBudget",
                            selectedOptionBudget
                              ? selectedOptionBudget.value
                              : "",
                          );
                          setSelectedOptionBudget(selectedOptionBudget);
                        }}
                        stringValue="selectedOptionBudget"
                        options={BudgetValues}
                        placeholder="Budget"
                        instanceId="react-select-budget2"
                        label="budget"
                        multi={false}
                        mobileWidth="17rem"
                        formik={formik}
                        value={selectedOptionBudget}
                        formikTouched={formik.touched["selectedOptionBudget"]}
                        formikError={formik.errors["selectedOptionBudget"]}
                      />
                      </label>
                      <label>
                      <SelectComponent
                      id="time-input"
                        className="mb-2 -ml-[3px] border-b-[5px] border-gray-300"
                       // required={false}
                        selectWidth="31rem"
                        isMobile={isMobile}
                        defaultValue={formik.values["selectProjectBegin"]}
                        onChange={(selectProjectBegin) => {
                          formik.setFieldValue(
                            "selectProjectBegin",
                            selectProjectBegin ? selectProjectBegin.value : "",
                          );
                          setSelectProjectBegin(selectProjectBegin);
                        }}
                        stringValue="selectProjectBegin"
                        options={BeginProject}
                        placeholder="How soon would you like to begin?"
                        instanceId="react-select-project-begin"
                        label="react-select-project-begin"
                        multi={false}
                        mobileWidth="17rem"
                        formik={formik}
                        value={selectProjectBegin}
                        formikTouched={formik.touched["selectProjectBegin"]}
                        formikError={formik.errors["selectProjectBegin"]}
                      />
                      </label>
                      <label>
                      <SelectComponent
                      id="decision-input"
                        className="mb-2 -ml-[3px] border-b-[5px] border-gray-300"
                       // required={false}
                        selectWidth="31rem"
                        isMobile={isMobile}
                        defaultValue={formik.values["selectHiringDecision"]}
                        onChange={(selectHiringDecision) => {
                          formik.setFieldValue(
                            "selectHiringDecision",
                            selectHiringDecision
                              ? selectHiringDecision.value
                              : "",
                          );
                          setSelectHiringDecision(selectHiringDecision);
                        }}
                        stringValue="selectHiringDecision"
                        options={hiringDecision}
                        placeholder="How likely are you to hire?"
                        instanceId="react-select-hiring"
                        label="react-select-hiring"
                        multi={false}
                        mobileWidth="17rem"
                        formik={formik}
                        value={selectHiringDecision}
                        formikTouched={formik.touched["selectHiringDecision"]}
                        formikError={formik.errors["selectHiringDecision"]}
                      />
                      </label>
                      <label className="w-[100%]">
                      <InputComponent
                        className="w-[100%] border-b-[5px] border-gray-300 text-gray-800 outline-none"
                        value="business"
                        id="business-input"
                        placeholder="Name of Business"
                        formik={formik}
                      />
</label>
                      <div className="mb-2 border-b-[5px] border-gray-300 w-[100%] sm:w-[100%]  text-gray-800 outline-none">
                        <TimezoneSelector onTimeChange={handleTimeChange} />
                        {formik.touched.timeToCall &&
                        formik.errors.timeToCall ? (
                          <div className="error">
                            {formik.errors.timeToCall}
                          </div>
                        ) : null}
                      </div>

                      <div className="mb-2 col-span-2">
                        <label>
                        <textarea
                          name="message"
                          id="message2"
                          className="w-[15rem] sm:w-[18rem] md:w-[22rem] lg:w-[30rem] h-[100px]  border border-gray-300 px-2 py-[5px]  rounded-2xl bg-white  input-placeholder text-[13px]"
                          value={formik.values.message}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          rows={4}
                          placeholder="Type your message here"
                        />
                        </label>
                        {formik.touched.message && formik.errors.message ? (
                          <div className="error">{formik.errors.message}</div>
                        ) : null}
                      </div>
                    </>
                  )}

          {/*}          <div
                    style={{
                      paddingBottom: "5px",
                      display: "flex",
                      justifyContent: "start",
                      flexDirection: "column",
                    }}
                  >
                    {showCaptchaError && (
                      <p className="text-[13px] text-red-600 py-2">
                        {" "}
                        Please fill recaptcha{" "}
                      </p>
                    )}
                  <ReCAPTCHA
                      sitekey={`${process.env.NEXT_PUBLIC_RECAPTCHA_PUBLIC_KEY}`} // Replace with your site key
                      onChange={onRecaptchaChange}
                  /> 
                  <ReCAPTCHA
  sitekey={getSiteKey()}
  onChange={onRecaptchaChange}
  {...(getSiteKey() ? {} : { 'data-size': 'invisible' })}
/>
                  </div>
*/}
                  <div className="mb-2 col-span-2">
                <label>
            <input className="ml-2 "
             type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              required
            />
            <span className="ml-2 text-xs text-gray-700">
            I hereby give my consent to Techcelerant to use my number for  sending <br className=" hidden xl:inline "/> <span className="inline ml-0 xl:ml-7"> me SMS messages</span>
        </span>
      </label>
                </div>

                  {isFormSubmited ? (
                    <button
                      disabled
                      type="submit"
                      className={`relative w-[100%]  cursor-not-allowed	 sm:min-w-[20rem] h-[2.5rem] bg-gray-300 shadow-lg shadow-gray-500 text-black rounded-full cursor-pointer ${
                        isVisible === false ? "mt-5" : ""
                      }`}
                    >
                      {" "}
                      Sent
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className={`relative w-[15rem] sm:min-w-[100%] h-[2.5rem] ${formik.isValid && !formik.isSubmitting ? "  form-buttom-submit  bg-violet-400" : "bg-gray-300"} text-white rounded-full cursor-pointer`}
                      disabled={!formik.isValid || formik.isSubmitting}
                    >
                      {formik.isSubmitting ? "Sending..." : "Submit"}
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
};
export default Form;
