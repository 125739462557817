import React, { useState } from "react";
import Select from "react-select";

import { timeZones, timeSlots } from "../data/formData";

const TimezoneSelector = ({ onTimeChange }) => {
  const [selectedTimeZone, setSelectedTimeZone] = useState(null); // Use null for initial state
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null); // Use null for initial state

  const handleTimeZoneChange = (selectedOption) => {
    setSelectedTimeZone(selectedOption);

    // Reset time slot when time zone changes
    setSelectedTimeSlot(null);

    // Notify parent component about the selected time zone, and reset time slot
    onTimeChange(selectedOption ? selectedOption.value : null, null);
  };

  const handleTimeSlotChange = (selectedOption) => {
    setSelectedTimeSlot(selectedOption);
    // Notify parent component about the selected time slot
    onTimeChange(
      selectedTimeZone ? selectedTimeZone.value : null,
      selectedOption,
    );
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      display: "flex",
      width: "auto",
      fontSize: "13px",
      minWidth: 200,
      marginRight: "10px",
    }),
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isFocused
        ? "linear-gradient(to bottom, #624da0, #984d7d)"
        : "white",
      fontSize: "13px",
      fontFamily: "inherit",
      background: isSelected
        ? "darkgray"
        : isFocused
          ? "linear-gradient(to bottom, #624da0, #984d7d)"
          : "white",
      color: isFocused ? "white" : "gray",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "white",
    }),

    singleValue: (styles) => {
      return {
        ...styles,
        color: "gray",
        fontFamily: "inherit",
        fontSize: "13px",
      };
    },
  };

  const timeZoneOptions = timeZones.map((zone) => ({
    value: zone.abbreviation,
    label: zone.name,
  }));

  // Generate time slot options based on the selected time zone
  const timeSlotOptions = selectedTimeZone
    ? timeSlots[selectedTimeZone.value].map((slot, index) => ({
        value: index, // Use index as value for uniqueness
        label: `${slot.start} - ${slot.end}`,
      }))
    : [];

  return (
    <div className="time-zone-container">
      <style jsx>
        {`
          .time-zone-container {
            display: flex;
            align-items: center;
          }
          @media screen and (max-width: 768px) {
            .time-zone-container {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              gap: 0.5rem;
              margin-bottom: 0.5rem;
            }
          }
        `}
      </style>
      <label className="w-[100%] sm:w-[100%]">
      <Select
        styles={customStyles}
        options={timeZoneOptions}
        onChange={handleTimeZoneChange}
        value={selectedTimeZone}
        placeholder="Select Timezone"
        className="w-[100%]"
      />
      </label>
      <label className="w-[100%] sm:w-[100%]">
      <Select
        styles={customStyles}
        options={timeSlotOptions}
        onChange={handleTimeSlotChange}
        value={selectedTimeSlot}
        placeholder="Prefered time to contact"
        className="w-[100%]"
      />
      </label>
    </div>
  );
};

export default TimezoneSelector;
